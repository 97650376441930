.one-col-box.error[data-v-03589122] {
  background-color: #f5f5f5;
  min-height: 200px;
}
.one-col-box.error h2[data-v-03589122] {
  font-size: 1.87rem;
  margin: 0;
  padding-bottom: 20px;
  padding-top: 40px;
  line-height: 1.21886em;
  font-weight: normal;
  color: #212121;
}
.one-col-box.error p[data-v-03589122] {
  margin: 0;
  padding-bottom: 20px;
  color: rgb(102, 102, 102);
}
.one-col-box.error p[data-v-03589122]:last-child {
  padding-bottom: 40px;
}
.one-col-box.error div[data-v-03589122] {
  padding-bottom: 40px;
}
@media (min-width: 768px) {
.flex-btn[data-v-03589122] {
    width: auto;
}
}