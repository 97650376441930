.register-left-section[data-v-ff02818c] {
  background: #ffffff;
  height: 100%;
}
.register-left-section .iframe-wrapper[data-v-ff02818c] {
  padding: 0 9px;
  line-height: 0;
}
.register-left-section .iframe-wrapper iframe[data-v-ff02818c] {
  width: 100%;
  height: 100%;
  min-height: 782px;
}